import { Button } from "@components/atoms/Button"
import { FooterHero } from "@components/atoms/FooterHero"
import { defineView } from "eddev/views"

export default defineView("404", () => {
  return (
    <div className="w-full h-full relative flex flex-col justify-between">
      <div>
        <h1 className="type-title-xxl-light w-full pt-5 md:pb-[100px] px-5 md:px-0">
          404 &mdash;
          <br className="md:hidden" /> Page not found
        </h1>
        <div className="oversize border-b hidden md:block" />
        <div className="grid-auto w-full mb-10 px-5 md:px-0">
          <div className="col-span-12 py-6">
            <p className="type-title-m-light">It looks like this page has gone off the grid.</p>
          </div>
          <div className="col-span-12 flex justify-start">
            <Button href="/">Home</Button>
          </div>
        </div>
      </div>
      <FooterHero />
    </div>
  )
})
